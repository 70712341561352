import { dateParser } from './parser';

export const monitorioConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return dateParser({
      id: snapshot.id,
      ...data,
    });
  },
};

export const reducedMonitorioConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return dateParser({
      id: snapshot.id,
      dni: data.dni,
      fullName: data.fullName,
      refId: data.refId,
      loanId: data.loanId,
      hasDocumentation: data.hasDocumentation ?? false,
      street: data.street,
      zipCode: data.zipCode,
      city: data.city,
    });
  },
};
