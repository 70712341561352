import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Divider, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from './Logo';

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;

  return (
    <AppBar
      enableColorOnDark
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        backgroundImage: 'unset',
        color: 'text.secondary',
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
          <IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
            <MenuIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
          <RouterLink to="/">
            <Logo small />
          </RouterLink>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
