export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const LOAN_STATUS = {
  ACTIVE: 'ACTIVE',
  DELINQUENT: 'DELINQUENT',
  CLOSED: 'CLOSED',
  BULNES_SOLD2022: 'BULNES_SOLD2022',
  DEFECTIVE: 'DEFECTIVE',
  PROOF_OF_PAYMENT: 'PROOF_OF_PAYMENT',
  ALL: 'ALL',
};

export const PAYMENT_STATUS = {
  ALL: 'ALL',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
  TIMED_OUT: 'TIMED_OUT',
  REFUNDED: 'REFUNDED',
  RETAINED: 'RETAINED',
  PAYMENT_PROOF: 'PAYMENT_PROOF',
};

export const PAYMENT_STATUS_DISPUTE = {
  OPEN: 'OPEN',
  WON: 'WON',
  CANCELED: 'CANCELED',
};

export const PAYMENT_METHOD = {
  BANK_TRANSFER: 'bankTransfer',
  CARD: 'creditCard',
};

export const PAYMENT_CHANNEL = {
  REGULAR_TRANSFER: 'regular_transfer',
};

export const PAYMENT_INITIATED_BY = {
  PAYMENT_PLAN_INSTALMENT: 'PAYMENT_PLAN_INSTALMENT',
  PALEATOR: 'PALEATOR',
  PAYMENT_LINK: 'PAYMENT_LINK',
  ORGANIC: 'ORGANIC',
};

export const PAYMENT_REQUESTER = {
  USER: 'user',
  TRANSFER: 'transfer',
};

export const PAYMENT_PLAN_STATUS = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  DUE: 'DUE',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
};

export const PAYMENT_PLAN_INSTALMENT_STATUS = {
  PAID: 'PAID',
  PENDING: 'PENDING',
  DUE: 'DUE',
  CONFIRMATION_PENDING: 'PENDING_OF_CONFIRMATION',
};

export const SMS_TYPES = {
  PAYMENT_LINK: 'PAYMENT_LINK',
  TRANSFER_DATA: 'TRANSFER_DATA',
};

export const ROLES = {
  ALL: 'ALL',
  ADMIN: 'ADMIN',
  SUPERVISOR: 'SUPERVISOR',
  RECOVERY_AGENT: 'RECOVERY_AGENT',
};

export const IMPORT_STATUS = {
  LOADING: 'LOADING',
  IN_PROGRESS: 'IN_PROGRESS',
  READY: 'READY',
  ROLLBACK: 'ROLLBACK',
  SUCCESS: 'SUCCESS',
};

export const LOAN_DETAILS_MODAL_CONTENT = {
  PAYMENT_PLAN: 'PAYMENT_PLAN',
  PAYMENT: 'PAYMENT',
  PROOF_OF_PAYMENT: 'PROOF_OF_PAYMENT',
  INCIDENT: 'INCIDENT',
  TRANSFER_DATA: 'TRANSFER_DATA',
  PAYMENT_LINK: 'PAYMENT_LINK',
  CARD_VERIFICATION: 'CARD_VERIFICATION',
};

export const CUSTOMER_MODAL_CONTENT = {
  PAYMENT_PLAN: 'PAYMENT_PLAN',
  PAYMENT_LINK: 'PAYMENT_LINK',
  TRANSFER_LINK: 'TRANSFER_LINK',
};

export const PAYMENT_PLAN_MODAL_CONTENT = {
  CANCEL: 'CANCEL',
  SEND_LINK: 'SEND_LINK',
};

export const INCIDENT_STATUS = {
  ALL: 'ALL',
  OPENED: 'OPENED',
  RESOLVED: 'RESOLVED',
  DISMISSED: 'DISMISSED',
};

export const INCIDENT_PRIORITY = {
  ALL: 'ALL',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const PAYMENT_FILTER = {
  ALL: 'ALL',
  HAVE: 'HAVE',
  HAVE_NOT: 'HAVE_NOT',
};

export const CALL_STAUS = {
  CALL_FROM_USER: 'CALL_FROM_USER',
  MISSED_CALL_FROM_USER: 'MISSED_CALL_FROM_USER',
  CALL_FROM_AGENT: 'CALL_FROM_AGENT',
  MISSED_CALL_FROM_AGENT: 'MISSED_CALL_FROM_AGENT',
};

export const MONITORIO_STATUS = {
  ALL: 'ALL', // REVISAR DATOS
  REVIEW_DATA: 'REVIEW_DATA', // REVISAR DATOS
  PREVIOUS_PAYMENT_REQUIREMENT: 'PREVIOUS_PAYMENT_REQUIREMENT', // REQUERIMIENTO PREVIO DE PAGO
  PREVIOUS_PAYMENT_REQUIREMENT_SEND: 'PREVIOUS_PAYMENT_REQUIREMENT_SEND', // REQUERIMIENTO PREVIO DE PAGO ENVIADO
  MISSING_DOCUMENTS: 'MISSING_DOCUMENTS', // FALTAN DOCUMENTOS
  READY_TO_SUBMIT: 'READY_TO_SUBMIT', // LISTO PARA PRESENTAR
  SUBMITTED: 'SUBMITTED', // PRESENTADO
  PROCESSING: 'PROCESSING', // ADMITIDO A TRAMITE
  REQUIREMENT: 'REQUIREMENT', // REQUERIMIENTO
  REQUIREMENT_ANSWERED: 'REQUIREMENT_ANSWERED', // REQUERIMIENTO CONTESTADO
  DESISTING: 'DESISTING', // DESISTIMIENTO
  DESISTING_NOTIFIED: 'DESISTING_NOTIFIED', // DESISTIMIENTO NOTIFICADO → Cuando la persona paga y hay que informar al juzgado
  FINAL_JUDGEMENT: 'FINAL_JUDGEMENT', // EJECUTORIA → No contesta el usuario al monitorio
  EXECUTING: 'EXECUTING', // EJECUTANDO → Procedimiento de ejecución presentado
  OPPOSED: 'OPPOSED', // OPUESTO
  EXECUTED_BY_SEIZURE: 'EXECUTED_BY_SEIZURE', // EJECUTADO POR EMBARGO
  SEIZURE_FAILED: 'SEIZURE_FAILED', // EMBARGO FALLIDO
};

export const PAYMENT_TYPE = {
  CHARGE: 'CHARGE',
  VERIFICATION: 'VERIFICATION',
  SPLITTED: 'SPLITTED',
  REFUND: 'REFUND',
  DISPUTE: 'DISPUTE',
};

export const MONITORIO_ACTIONS = {
  PRESENT_ALL: 'PRESENT_ALL',
  REVIEW_DATA: 'REVIEW_DATA',
  PREVIOUS_PAYMENT_REQUIREMENT: 'PREVIOUS_PAYMENT_REQUIREMENT',
  PREVIOUS_PAYMENT_REQUIREMENT_SEND: 'PREVIOUS_PAYMENT_REQUIREMENT_SEND',
};

export const MONITORIO_DOCUMENTS_TYPE = {
  MONITORIO: 'MONITORIO',
  PREVIOUS_PAYMENT_REQUIREMENT: 'PREVIOUS_PAYMENT_REQUIREMENT',
};

export const MONITORIO_FILES_TO_DOWNLOAD = {
  MONITORIO: {
    suffix: 'escrito_monitorio.pdf',
    folder: 'monitorios',
  },
  PREVIOUS_PAYMENT_REQUIREMENT: {
    suffix: 'requerimiento_previo_de_pago.pdf',
    folder: 'requerimientoPago',
  },
  PREVIOUS_PAYMENT_REQUIREMENT_SEND: {
    suffix: 'requerimiento_previo_de_pago.pdf',
    folder: 'requerimientoPago',
  },
};

export const PHONE_STATUS = {
  OK: 'OK',
  OUT_OF_SERVICE: 'OUT_OF_SERVICE',
  TEST: 'TEST',
};
