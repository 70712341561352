import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography } from '@mui/material';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import BankAccountIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/EuroOutlined';
import useAuth from '../../hooks/useAuth';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ClipboardList from '../../icons/ClipboardList';
import UserIcon from '../../icons/User';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { ROLES } from 'src/constants';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { hasPermission } from 'src/utils/auth';
import Phone from '@mui/icons-material/Phone';

const sections = [
  {
    title: 'general',
    items: [
      {
        title: 'dashboard',
        path: '/',
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        roles: [ROLES.ADMIN, ROLES.SUPERVISOR],
        title: 'incidents',
        path: '/incidents',
        icon: <AllInboxIcon fontSize="small" />,
      },
      {
        roles: [ROLES.ADMIN, ROLES.SUPERVISOR],
        title: 'performance',
        path: '/performance',
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        roles: [ROLES.ADMIN, ROLES.SUPERVISOR],
        title: 'monitorio',
        path: '/monitorio',
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        roles: [ROLES.ADMIN, ROLES.SUPERVISOR],
        title: 'taxes',
        path: '/taxes',
        icon: <AccountBalanceWalletIcon fontSize="small" />,
      },
      {
        title: 'loans',
        path: '/loans',
        icon: <ClipboardList fontSize="small" />,
      },
      {
        title: 'paymentPlans',
        path: '/paymentPlans',
        icon: <FeaturedPlayListIcon fontSize="small" />,
      },
      {
        roles: [ROLES.ADMIN, ROLES.SUPERVISOR],
        title: 'pendingPayments',
        path: '/pending-payments',
        icon: <PaymentIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'admin',
    roles: [ROLES.ADMIN],
    items: [
      {
        title: 'paleator',
        path: '/paleator',
        icon: <EuroIcon fontSize="small" />,
      },
      {
        title: 'importLoans',
        path: '/import-loans',
        icon: <ImportExportIcon fontSize="small" />,
      },
      {
        title: 'importPayments',
        path: '/import-payments',
        icon: <BankAccountIcon fontSize="small" />,
      },
      {
        title: 'importPhones',
        path: '/import-phones',
        icon: <Phone fontSize="small" />,
      },
      {
        title: 'agents',
        path: '/agents',
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: 'paymentLink',
        path: '/payment-link',
        icon: <AccountBalanceWalletIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: { lg: 'none', xs: 'flex' },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              <Typography color="textSecondary" variant="caption">
                {user.role}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section, idx) => {
            if (!hasPermission(section.roles, user.role)) {
              return <Box key={idx}></Box>;
            }
            return (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                user={user}
                sx={{
                  '& + &': {
                    mt: 3,
                  },
                }}
                {...section}
              />
            );
          })}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            width: 280,
            display: { xs: 'block', lg: 'none' },
          },
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
            display: { xs: 'none', lg: 'block' },
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
