import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { dayLapseFromDate } from 'src/utils/dateUtils';
import getFirestore from 'src/utils/firestore';
import { sanitizeText } from 'src/utils/stringUtils';

const initialState = {
  pendingPayments: [],
  discardedPayments: [],
  firstPendingPaymentId: null,
  firstDiscardPaymentId: null,
};

const name = 'pendingPayments';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setPendingPayments(state, action) {
      state.pendingPayments = action.payload;
    },
    setDiscardedPayments(state, action) {
      state.discardedPayments = action.payload;
    },
    setFirstPendingPaymentId(state, action) {
      state.firstPendingPaymentId = action.payload;
    },
    setFirstDiscardedPaymentId(state, action) {
      state.firstDiscardPaymentId = action.payload;
    },
  },
});

export const { reducer } = slice;

const queryToPendingPayments = (options) => {
  let baseQuery = getFirestore()
    .collection('pendingPayments')
    .where('discarded', '==', options?.discarded)
    .orderBy('operDate', 'desc')
    .withConverter(pendingPaymentConverter);

  if (options?.amount) {
    const formatted = Intl.NumberFormat('it', { minimumFractionDigits: 2 }).format(
      parseFloat(options.amount.replace(',', '.'))
    );

    baseQuery = baseQuery.where('row.IMPORTE', '==', formatted);
  }

  if (options?.startDate || options?.endDate) {
    if (options.startDate && options.endDate === null) {
      const { startDate, endDate } = dayLapseFromDate(options.startDate);
      options.startDate = startDate;
      options.endDate = endDate;
    }
    if (options?.startDate) {
      baseQuery = baseQuery.where('operDate', '>=', options.startDate);
    }
    if (options?.endDate) {
      baseQuery = baseQuery.where('operDate', '<=', options.endDate);
    }
  }

  if (options?.concept) {
    const filter = sanitizeText(options.concept);

    baseQuery = baseQuery.where('concept_lowercase', '==', filter);
  }

  if (options?.endBefore) {
    baseQuery = baseQuery.endBefore(options.endBefore).limitToLast(options.limit);
  }
  if (options?.startAfter) {
    baseQuery = baseQuery.startAfter(options.startAfter).limit(options.limit);
  }

  if ((options?.limit || options.startAfter) && !options.endBefore) {
    baseQuery = baseQuery.limit(options?.limit);
  }
  return baseQuery;
};

export const getPendingPayments = (options) => async (dispatch, state) => {
  return queryToPendingPayments(options).onSnapshot(
    (querySnapshot) => {
      let payments = querySnapshot.docs.map((doc) => doc.data());

      if (options?.discarded === true) {
        if (!state()[name].firstDiscardPaymentId && !isEmpty(payments)) {
          dispatch(slice.actions.setFirstDiscardedPaymentId(payments[0].id));
        }
        dispatch(slice.actions.setDiscardedPayments(payments));
      } else {
        if (!state()[name].firstPendingPaymentId && !isEmpty(payments)) {
          dispatch(slice.actions.setFirstPendingPaymentId(payments[0].id));
        }
        dispatch(slice.actions.setPendingPayments(payments));
      }
    },
    (err) => {},
    () => {}
  );
};

export const getPendingPaymentsToExport = (options) => {
  return queryToPendingPayments(options)
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        const header = 'id;concept;amount;operation date\n';
        const data = querySnapshot.docs
          .map((it) => {
            const payment = it.data();
            return `${payment.id};${payment.concept};${payment.amount};${payment.operDate}`;
          })
          .join('\n');
        return header.concat(data);
      }
      return [];
    });
};

export const pendingPaymentConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const { row, discarded, operDate } = snapshot.data(options);

    const pendingPayment = {
      id: snapshot.id,
      concept: row.CONCEPTO,
      operationDate: row['FECHA OPER'],
      operDate: operDate.toDate().toISOString(),
      amount: getAmountInCents(row.IMPORTE),
      discarded,
    };

    return pendingPayment;
  },
};

const getAmountInCents = (amountStr) => {
  // e.g: 1.018,64
  const AMOUNT_REGEX = /[.,€\s]/g;
  return parseInt(amountStr.replace(AMOUNT_REGEX, ''), 10);
};
