export const financeConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      boughtDebt: data.total,
      amortized: data?.amortized || 0,
      entity: data.entityName,
    };
  },
};
