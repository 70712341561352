import { createSlice } from '@reduxjs/toolkit';
import getFirestore from 'src/utils/firestore';
import { DNIorNIE, FrenchDNIorNIE, isEmail } from 'src/utils/validators';
import { customerConverter } from './customerConverter';

const initialState = {
  error: null,
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
});

export const { reducer } = slice;

const getCustomerBaseQuery = (customerId) => {
  let baseQuery = getFirestore().collection('records').withConverter(customerConverter);

  if (DNIorNIE(customerId) || FrenchDNIorNIE(customerId)) {
    baseQuery = baseQuery.where('customer.dni', '==', customerId);
  } else if (isEmail(customerId)) {
    baseQuery = baseQuery.where('customer.email', '==', customerId);
  }

  return baseQuery;
};

export const getCustomer = (customerId) => {
  return getCustomerBaseQuery(customerId)
    .get()
    .then((querySnapshot) => {
      let personalInfo = {};
      let deleted = false;
      let totalLend = 0;
      let totalPaid = 0;
      let minToClose = 0;
      let loans = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        deleted = data.deleted;
        totalPaid += data.totalPaid;
        totalLend += data.lend;
        minToClose += data.minToClose;
        loans.push(data.loan);
        personalInfo = { ...personalInfo, ...data.customer };
      });

      return {
        personalInfo,
        deleted,
        loans,
        totalLend,
        totalPaid,
        minToClose,
        progress: +(totalPaid / totalLend).toFixed(2),
      };
    });
};

export const updatePhone = async (dni, phones) => {
  try {
    const customerRef = await getCustomerBaseQuery(dni).get();
    if (customerRef.empty) {
      throw new Error('No customer found for ', dni);
    }

    for await (const doc of customerRef.docs) {
      const docRef = doc.data();
      await docRef.ref.update({
        'customer.phoneNumbers': phones,
      });
    }
  } catch (e) {
    console.error('Fail updating cusotmer phones', e);
  }
};

export const updatePrimaryPhone = async (dni, phone) => {
  try {
    const customerRef = await getCustomerBaseQuery(dni).get();
    if (customerRef.empty) {
      throw new Error('No customer found for ', dni);
    }

    for await (const doc of customerRef.docs) {
      const docRef = doc.data();
      await docRef.ref.update({
        'customer.phone': phone,
      });
    }
  } catch (e) {
    console.error('Fail updating cusotmer phones', e);
  }
};
