import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import AccountPopover from './AccountPopover';
import MenuIcon from '@mui/icons-material/Menu';
import ContentSearch from './ContentSearch';
import LanguagePopover from './LanguagePopover';
import Logo from '../Logo';
import NotificationsPopover from './NotificationsPopover';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { settings, saveSettings } = useSettings();

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
          <IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
            <MenuIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <IconButton
          color="inherit"
          onClick={() => {
            saveSettings({
              ...settings,
              theme: settings.theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT,
            });
          }}
          size="large"
        >
          {settings.theme === THEMES.LIGHT ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>
        <LanguagePopover />
        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
