import { responsiveFontSizes, createTheme } from '@mui/material/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

export const getTheme = (mode) => {
  const theme = createTheme(themeOptions(mode));

  return responsiveFontSizes(theme);
};

const themeOptions = (mode) => ({
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },
      },
    },
    ...(mode === THEMES.LIGHT
      ? {
          MuiInputBase: {
            styleOverrides: {
              input: {
                '&::placeholder': {
                  opacity: 0.86,
                  color: '#42526e',
                },
              },
            },
          },
        }
      : {
          MuiTableCell: {
            styleOverrides: {
              root: {
                borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
              },
            },
          },
        }),
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
  palette: {
    ...(mode === THEMES.LIGHT
      ? {
          action: {
            active: '#6b778c',
          },
          background: {
            default: '#f4f5f7',
            paper: '#ffffff',
          },
          error: {
            contrastText: '#ffffff',
            main: '#F50057',
          },
          mode: 'light',
          primary: {
            contrastText: '#ffffff',
            main: '#3A7BFF',
          },
          success: {
            contrastText: '#ffffff',
            main: '#4caf50',
          },
          text: {
            primary: '#172b4d',
            secondary: '#6b778c',
          },
          warning: {
            contrastText: '#ffffff',
            main: '#ff9800',
          },
          gray: {
            contrastText: '#ffffff',
            main: '#47473a',
          },
          dispute: {
            contrastText: '#ffffff',
            main: '#CB904D',
          },
          disabled: {
            main: 'rgba(0, 0, 0, 0.26)',
          },
          default: {
            contrastText: '#ffffff',
            main: '#8f9ebc',
          },
        }
      : {
          background: {
            default: '#171c24',
            paper: '#222b36',
          },
          divider: 'rgba(145, 158, 171, 0.24)',
          error: {
            contrastText: '#ffffff',
            main: '#F50057',
          },
          mode: 'dark',
          primary: {
            contrastText: '#ffffff',
            main: '#3A7BFF',
          },
          success: {
            contrastText: '#ffffff',
            main: '#4caf50',
          },
          text: {
            primary: '#ffffff',
            secondary: '#919eab',
          },
          warning: {
            contrastText: '#ffffff',
            main: '#ff9800',
          },
          gray: {
            contrastText: '#ffffff',
            main: '#47473a',
          },
          dispute: {
            contrastText: '#ffffff',
            main: '#CB904D',
          },
          disabled: {
            main: 'rgba(255, 255, 255, 0.3)',
          },
          default: {
            contrastText: '#ffffff',
            main: '#8f9ebc',
          },
        }),
  },
  shape: {
    borderRadius: 16,
  },
  shadows: mode === THEMES.LIGHT ? lightShadows : darkShadows,
});
