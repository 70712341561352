import firebase from 'src/lib/firebase';

export const uploadFiles = (folder, files) => {
  const storage = firebase.storage().ref();

  const promises = files.map((file) => {
    return storage
      .child(`${folder}/${file.name}`)
      .put(file)
      .then((r) => r)
      .catch(() => 'error');
  });

  return Promise.allSettled(promises);
};

export const downloadFile = (file) => {
  const storageRef = firebase.storage().ref(file);
  return storageRef.getDownloadURL();
};

export const readFiles = async (folder) => {
  const storage = firebase.storage().ref();

  return await storage
    .child(folder)
    .listAll()
    .then((res) => {
      // TODO check if user has subfolders and files
      // if (res.prefixes && res.prefixes.length > 0) {
      //   return Promise.all(
      //     res.prefixes.map((folderRef) => readFiles(folderRef.fullPath))
      //   ).then((responses) => responses.flatMap((it) => it));
      // }
      return Promise.all(readFolderFiles(res));
    });
};

const readFolderFiles = (res) =>
  res.items.map((itemRef) => {
    return Promise.all([itemRef.getDownloadURL(), itemRef.getMetadata()]).then((data) => {
      return {
        name: itemRef.name,
        type: data[1].contentType,
        url: data[0],
      };
    });
  });
