import { createSlice } from '@reduxjs/toolkit';
import getFirestore from 'src/utils/firestore';
import { financeConverter } from './financeTaxesConverter';

const initialState = {
  loans: null,
};

const slice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    setLoans(state, action) {
      state.loans = action.payload;
    },
  },
});

export const { reducer } = slice;

export const fetchFinanceTaxes = (filters, entities) => async (dispatch) => {
  let baseQuery = getFirestore()
    .collection('records')
    .withConverter(financeConverter)
    .orderBy('__name__');

  if (filters?.endBefore) {
    baseQuery = baseQuery.endBefore(filters.endBefore).limitToLast(filters.limit);
  }
  if (filters?.startAfter) {
    baseQuery = baseQuery.startAfter(filters.startAfter).limit(filters.limit);
  }

  if (filters.limit) {
    baseQuery = baseQuery.limit(filters.limit);
  }

  return baseQuery.get().then((querySnapshot) => {
    if (querySnapshot.empty) {
      dispatch(slice.actions.setLoans(null));
      return;
    }
    const loans = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const amountPaid = data.boughtDebt * entities[data.entity].rate;
      return {
        ...data,
        amountPaid,
        profit: data.amortized - amountPaid,
      };
    });
    dispatch(slice.actions.setLoans(loans));
  });
};
