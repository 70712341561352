import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, isEqual } from 'lodash';
import { PAYMENT_STATUS, PAYMENT_TYPE } from 'src/constants';
import { monthLapse } from 'src/utils/dateUtils';
import getFirestore from 'src/utils/firestore';
import { dateParser } from './parser';
import {
  approvedPaymentConverter,
  mainConverter,
  summaryPaymentConverter,
} from './paymentsConverter';

const initialState = {
  payments: [],
  firstPaymentId: null,
};
const name = 'payments';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setPayments(state, action) {
      state.payments = action?.payload || [];
    },
    setFirstPaymentId(state, action) {
      state.firstPaymentId = action?.payload || null;
    },
  },
});

export const { reducer } = slice;

const queryToPayments = (options) => {
  let baseQuery = getFirestore().collection('payments').orderBy('confirmedAt', 'desc');

  if (options?.loanId) {
    baseQuery = baseQuery.where('loanId', '==', options.loanId);
  }

  if (options?.status && options?.status !== PAYMENT_STATUS.ALL) {
    baseQuery = baseQuery.where('status', '==', options.status);
  }

  if (options?.statusList && !isEqual(options?.statusList, [PAYMENT_STATUS.ALL])) {
    baseQuery = baseQuery.where(
      'status',
      'in',
      options?.statusList.filter((it) => it !== PAYMENT_STATUS.ALL)
    );
  }

  if (options?.entity && options.entity !== PAYMENT_STATUS.ALL) {
    baseQuery = baseQuery.where('entity', '==', options.entity);
  }

  if (options?.amount) {
    baseQuery = baseQuery.where('amount', '==', parseFloat(options.amount.replace(',', '.')) * 100);
  }

  if (options?.startDate) {
    baseQuery = baseQuery.where('confirmedAt', '>=', options.startDate);
  }

  if (options?.endDate) {
    baseQuery = baseQuery.where('confirmedAt', '<=', options.endDate);
  }

  if (options?.endBeforeDate) {
    baseQuery = baseQuery.endBefore(new Date(options.endBeforeDate)).limitToLast(options.limit);
  }
  if (options?.startAfterDate) {
    baseQuery = baseQuery.startAfter(new Date(options.startAfterDate)).limit(options.limit);
  }

  if (options?.limit && !options?.startAfterDate && !options.endBeforeDate) {
    baseQuery = baseQuery.limit(options?.limit);
  }

  if (options?.converter) {
    baseQuery = baseQuery.withConverter(summaryPaymentConverter);
  } else {
    baseQuery = baseQuery.withConverter(mainConverter);
  }

  return baseQuery;
};

export const getPayments = (options) => async (dispatch, state) => {
  return queryToPayments(options).onSnapshot(
    (querySnapshot) => {
      if (!querySnapshot.empty) {
        const payments = querySnapshot.docs.map((it) => dateParser(it.data()));
        // .filter((payment) => isNotTimeoutOrVerification(payment));

        if (options?.sort) {
          payments.sort((p1, p2) => (p1.confirmedAt < p2.confirmedAt ? -1 : 1));
        }
        if (!state()[name].firstPaymentId && !isEmpty(payments)) {
          dispatch(slice.actions.setFirstPaymentId(payments[0].id));
        }
        dispatch(slice.actions.setPayments(payments));
      } else {
        dispatch(slice.actions.setPayments([]));
      }
    },
    (err) => {
      console.log('Error query payments');
      console.error(err);
    }
  );
};

export const getPaymentsToExport = (options) => {
  return queryToPayments(options)
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        const header =
          'id;status;loanId;dni;name;fecha de importacion;fecha de pago;amount;loanYear;concept;method\n';
        const data = querySnapshot.docs
          .filter((doc) => isNotTimeoutOrVerification(doc.data()))
          .map((it) => {
            const payment = it.data();
            return `${payment.id};${payment.status};${payment.loanId};${payment.dni};${
              payment.fullName
            };${payment.createdAt};${payment.confirmedAt || ''};${payment.amount};${
              payment.loanCreationYear
            };${payment.transferConcept};${payment.paymentMethod}`;
          })
          .join('\n');
        return header.concat(data);
      }
      return [];
    });
};

const isNotTimeoutOrVerification = (payment) => {
  return payment.status !== PAYMENT_STATUS.TIMED_OUT && payment.type !== PAYMENT_TYPE.VERIFICATION;
};

export const getAmortizedByAgentInCurrentMonth = (agent) => {
  const { startDate, endDate } = monthLapse(new Date());
  return getFirestore()
    .collection('payments')
    .where('agent', '==', agent)
    .where('confirmedAt', '>=', startDate)
    .where('confirmedAt', '<=', endDate)
    .withConverter(approvedPaymentConverter)
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        return querySnapshot.docs
          .map((it) => it.data())
          .filter(
            (it) =>
              [PAYMENT_STATUS.APPROVED, PAYMENT_STATUS.REFUNDED, PAYMENT_STATUS.RETAINED].includes(
                it.status
              ) &&
              [PAYMENT_TYPE.CHARGE, PAYMENT_TYPE.DISPUTE, PAYMENT_TYPE.REFUND].includes(it.type)
          )
          .map((it) => it.amount)
          .reduce((a, b) => a + b, 0);
      }
      return 0;
    })
    .catch((e) => {
      console.error(e);
      return 0;
    });
};
