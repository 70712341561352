import { isArray } from 'lodash';
import firebase from 'src/lib/firebase';

export const dateParser = (obj) => {
  if (obj) {
    Object.entries(obj).forEach(([k, v], i) => {
      if (v && typeof v === 'object' && Object.keys(v).join('|') === 'seconds|nanoseconds') {
        try {
          obj[k] = v.toDate().toISOString();
        } catch (e) {
          obj[k] = v;
        }
      }
      if (typeof v === 'object') {
        obj[k] = dateParser(obj[k]);
      }
      if (isArray(v)) {
        obj[k] = v.map(dateParser);
      }
    });
  }
  return obj;
};

export const parseDateToFirebase = (item) => {
  const obj = isArray(item) ? [...item] : { ...item };
  Object.entries(obj).forEach(([k, v], i) => {
    if (typeof v === 'string' && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(v)) {
      obj[k] = firebase.firestore.Timestamp.fromDate(new Date(v));
    }
    if (typeof v === 'object') {
      obj[k] = parseDateToFirebase(obj[k]);
    }
    if (isArray(v)) {
      obj[k] = v.map(parseDateToFirebase);
    }
  });
  return obj;
};
