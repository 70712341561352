export const DNIorNIE = (value) => {
  const AvgDNIRegex = /^\d{8}[-]?[a-zA-Z]$/;
  const NIERegex = /^[xyzXYZ][- ]?[0-9]{7}[- ]?[a-zA-Z]$/;
  return AvgDNIRegex.test(value) || NIERegex.test(value);
};

export const FrenchDNIorNIE = (value) => {
  const FrenchDNIRegex = /^\w+\d{4}-\d{2}-\d{2}/gm;
  return FrenchDNIRegex.test(value);
};

export const isRefId = (value) => {
  // TODO UPDATE THIS REGEX FOR EACH NEW ENTITY WE ADD
  const refIdRegex =
    /(A\d+$)|(FV\d+$)|(^\d+$)|((pmt-|pagantis-|pmtda-|qb-|pocopay-|sequra-|ocacb-)\d+$)/gm;
  return refIdRegex.test(value);
};

export const isEmail = (value) => {
  return value.includes('@') && value.includes('.');
};

export const isPhone = (value) => {
  const spainPhoneRegex = /^([+]?34)?\d{9}$/;
  const italianPhoneRegex = /^(([+]|00)39)?((3[1-6][0-9]))(\d{7})$/;
  const frenchPhoneRegex = /^((\+)33|0)[1-9](\d{2}){4}$/;
  return (
    spainPhoneRegex.test(value) || italianPhoneRegex.test(value) || frenchPhoneRegex.test(value)
  );
};
