import { Suspense, useEffect, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import './i18n';
import GlobalStyles from './components/GlobalStyles';
import RTL from './components/RTL';

import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { getTheme } from './theme';
import * as rollbar from './lib/rollbar';

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
    rollbar.ignoreChunkMessages();
  }, []);

  const theme = useMemo(() => getTheme(settings.theme), [settings.theme]);

  /*const theme = createTheme(
    adaptV4Theme({
      direction: settings.direction,
      responsiveFontSizes: settings.responsiveFontSizes,
      roundedCorners: settings.roundedCorners,
      theme: settings.theme,
      language: i18n.language,
    })
  );*/

  return (
    <Suspense fallback={<></>}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <RTL direction={settings.direction}>
            <SnackbarProvider dense maxSnack={3}>
              <GlobalStyles />
              {auth.isInitialized ? content : <SplashScreen />}
            </SnackbarProvider>
          </RTL>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

export default App;
