import { ROLES } from 'src/constants';
import firebase from 'src/lib/firebase';

const getAuth = () => {
  const auth = firebase.auth();

  return auth;
};

export const sendPaswordResetEmail = (email) => getAuth().sendPasswordResetEmail(email);

export const hasPermission = (sectionRoles, userRole) => {
  if (!sectionRoles || sectionRoles.length === 0 || sectionRoles.includes(userRole)) {
    return true;
  }
  return false;
};

export const isAdmin = (userRole) => userRole === ROLES.ADMIN;
export const isSupervisor = (userRole) => userRole === ROLES.SUPERVISOR;
export const isAgent = (userRole) => userRole === ROLES.RECOVERY_AGENT;

export default getAuth;
