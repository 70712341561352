import { isAfterDate } from 'src/utils/dateUtils';
import { dateParser } from './parser';

export const idConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return dateParser({ id: snapshot.id, ...data });
  },
};

export const loanConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    data.notes.sort((a, b) => (isAfterDate(b.createdAt.toDate(), a.createdAt.toDate()) ? 1 : -1));

    data.minToClose = data.total * data.discount;
    data.ableToClose = data.amortized >= data.minToClose;

    return { id: snapshot.id, ...dateParser(data) };
  },
};

export const searchLoanConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      refId: data.refId,
      dni: data.customer.dni,
      fullName: data.customer.fullName,
      entity: data.entityName,
      status: data.status,
      remaining: data.remaining,
      hasPaymentPlan: data.hasPaymentPlan,
    };
  },
};

export const customerLoanConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return data.customer.dni;
  },
};
