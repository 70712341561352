/* globals Rollbar */

export const clearUser = () => {
  Rollbar.configure({
    payload: {
      person: {
        id: null,
      },
    },
  });
};

export const setUser = (id) => {
  Rollbar.configure({
    payload: {
      person: {
        id,
      },
    },
  });
};

export const ignoreChunkMessages = () => {
  Rollbar.configure({
    ignoredMessages: [
      'Loading chunk .* failed',
      'ChunkLoadError',
      "Unexpected token '<'",
      'Please supply a public key when pushing analytics data',
    ],
  });
};
