import { createSlice } from '@reduxjs/toolkit';
import getFirestore from 'src/utils/firestore';

const initialState = {
  merchants: null,
};

const slice = createSlice({
  name: 'merchants',
  initialState,
  reducers: {
    setMerchants(state, action) {
      state.merchants = action.payload;
    },
  },
});

export const { reducer } = slice;

const queryToMerchants = (options) => {
  let baseQuery = getFirestore().collection('merchants');

  return baseQuery;
};

export const getMerchants = (options) => async (dispatch) => {
  queryToMerchants(options)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        dispatch(slice.actions.setMerchants(null));
      } else {
        dispatch(slice.actions.setMerchants(querySnapshot.docs.map((it) => it.data())));
      }
    });
};
