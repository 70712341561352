import { createSlice } from '@reduxjs/toolkit';
import { now } from 'src/utils/dateUtils';
import { IMPORT_STATUS } from 'src/constants';
import firebase from 'src/lib/firebase';
import { uploadFiles } from 'src/utils/firebaseStorageUtils';
import { dateParser } from './parser';
import getFirestore from 'src/utils/firestore';

const { LOADING, IN_PROGRESS } = IMPORT_STATUS;

const initialState = {
  loans: {
    status: LOADING,
    startedAt: null,
    results: [],
  },
  payments: {
    status: LOADING,
    startedAt: null,
    results: [],
  },
  phones: {
    status: LOADING,
    startedAt: null,
    results: [],
  },
  paymentLink: {
    status: LOADING,
    startedAt: null,
    results: [],
  },
};

const slice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    results(state, action) {
      state[action.payload.importJob] = {
        status: action.payload.status,
        startedAt: action.payload.startedAt,
        results: action.payload.results,
      };
    },
    loading(state, action) {
      state[action.payload.importJob] = {
        status: LOADING,
      };
    },
    initialize(state, action) {
      state[action.payload.importJob] = {
        status: IN_PROGRESS,
        startedAt: now().toISOString(),
      };
    },
  },
});

export const { reducer } = slice;

export const startImport =
  (importJob, filesToUpload, firestoreFolder, functionName, origin = null) =>
  async (dispatch) => {
    if (!importJob || !filesToUpload || !firestoreFolder || !functionName) {
      throw new Error('startImport: Missing parameters');
    }
    dispatch(slice.actions.initialize({ importJob }));
    const importLoansRequest = firebase.app().functions('europe-west2').httpsCallable(functionName);

    try {
      return uploadFiles(firestoreFolder, filesToUpload)
        .then(async (uploadResponse) => {
          await importLoansRequest({
            fileStoragePath: uploadResponse[0].value.metadata.fullPath,
            origin,
          });
        })
        .catch((e) => {
          throw e;
        });
    } catch (e) {
      throw e;
    }
  };

export const rollbackImport = (importJob, id) => async () => {
  const request = firebase
    .app()
    .functions('europe-west2')
    .httpsCallable(`rollback${importJob.charAt(0).toUpperCase() + importJob.slice(1)}`);

  await request({ id });
};

export const getImportJobResults = (job) => async (dispatch) => {
  try {
    return getFirestore()
      .collection('importJobs')
      .withConverter(importConverter)
      .doc(job)
      .onSnapshot(async (snapshot) => {
        const data = snapshot.data();

        if (data) {
          getResults(data, job, dispatch);
        } else {
          dispatch(updateResult(job, { status: IMPORT_STATUS.READY }));
        }
      });
  } catch (e) {
    console.error(`Fail to retrive import jobs for ${job}`, e);
    return null;
  }
};

const getResults = async (data, job, dispatch) => {
  data.ref
    .collection('results')
    .orderBy('finishedAt', 'asc')
    .withConverter(importConverter)
    .onSnapshot(async (resultsSnapshot) => {
      const results = resultsSnapshot.empty
        ? []
        : resultsSnapshot.docs.map((it) => {
            const r = it.data();
            delete r.ref;
            return r;
          });
      delete data.ref;
      dispatch(updateResult(job, { ...data, results }));
    });
};

export const updateResult = (importJob, data) => async (dispatch) => {
  dispatch(slice.actions.results({ importJob, ...data }));
};

const importConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return { ref: snapshot.ref, ...dateParser(data) };
  },
};
