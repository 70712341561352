import { generateUuid } from 'src/utils/builderUtils';
import { dayLapseFromDate } from 'src/utils/dateUtils';
import getFirestore from 'src/utils/firestore';
import { INCIDENT_STATUS } from 'src/constants';
import firebase from 'src/lib/firebase';
import { createSlice } from '@reduxjs/toolkit';
import { incidentConverter } from './incidentConverter';
import { isEmpty } from 'lodash';
import { getAllAgents } from './agent';

const initialState = {
  incidents: null,
  agents: null,
};

const slice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    setIncidents(state, action) {
      state.incidents = action?.payload;
    },
  },
});

export const { reducer } = slice;

export const getIncidents =
  (lang, query = null, page = 0, pageSize = 5) =>
  async (dispatch) => {
    const incidentTypes = await getIncidentsTypesAsHashMap(lang);

    let base = getFirestore().collection('incidents').withConverter(incidentConverter);

    if (query?.agent && query?.agent !== 'ALL') {
      base = base.where('agent', '==', query.agent);
    }

    if (query?.createdAt) {
      const d = dayLapseFromDate(query.createdAt);
      base = base.where('createdAt', '>=', d.startDate).where('createdAt', '<=', d.endDate);
    }

    if (query?.priority && query?.priority !== 'ALL') {
      base = base.where('priority', '==', query.priority);
    }

    if (query?.status && query?.status !== 'ALL') {
      base = base.where('status', '==', query.status);
    }

    if (query?.type && query?.type !== 'ALL') {
      base = base.where('type', '==', query.type);
    }
    if (query?.loanId) {
      base = base.where('loanId', '==', query.loanId);
    }

    return base.onSnapshot(async (querySnapshot) => {
      const incidents = querySnapshot.docs.map((doc) => doc.data());

      if (!isEmpty(incidents)) {
        const agents = await dispatch(getAllAgents());
        const incidentsWithAgents = incidents.map((incident) => {
          const { uuid, name } = agents.find(({ uuid }) => uuid === incident.agent);

          return {
            ...incident,
            type: incidentTypes[incident.type],
            agent: {
              uuid,
              name,
            },
          };
        });

        dispatch(
          slice.actions.setIncidents({
            pages: incidentsWithAgents.length,
            data: incidentsWithAgents.slice(page * pageSize, page * pageSize + pageSize),
          })
        );
      }
    });
  };

export const getIncidentsTypes = (lang, all = false) => {
  let base = getFirestore().collection('incidentTypes');

  if (!all) {
    base = base.where('type', '!=', 'ALL');
  }

  return base
    .get()
    .then((querySnapshot) => {
      const incidents = querySnapshot.docs.map((it) => it.data());
      incidents.sort((a, b) => (a.idx > b.idx ? 1 : -1));
      return incidents.map((it) => ({ value: it.type, text: it[lang] }));
    })
    .catch(console.error);
};

export const getIncidentsTypesAsHashMap = async (lang) => {
  const incidentTypes = await getIncidentsTypes(lang);
  return incidentTypes?.reduce((result, item) => {
    return { ...result, [item.value]: item.text };
  }, {});
};

export const createIncident = (incident, uid, loanId) => {
  const req = {
    ...incident,
    status: INCIDENT_STATUS.OPENED,
    id: generateUuid(),
    agent: uid,
    loanId,
    createdAt: firebase.firestore.Timestamp.now(),
    updatedAt: firebase.firestore.Timestamp.now(),
  };

  return getFirestore().collection('incidents').doc(req.id).set(req);
};

export const updateIncident = (id, values) => {
  values.updatedAt = firebase.firestore.Timestamp.now();
  return getFirestore()
    .collection('incidents')
    .doc(id)
    .set({ ...values }, { merge: true });
};
