import { createSlice } from '@reduxjs/toolkit';
import getFirestore from 'src/utils/firestore';
import firebase from 'src/lib/firebase';
import { ROLES } from 'src/constants';
import { generateUuid } from 'src/utils/builderUtils';

const initialState = {
  error: null,
  agents: null,
};

const slice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setAgents(state, action) {
      state.agents = action.payload;
    },
  },
});

export const { actions, reducer } = slice;

export const updateSms = async (customerId, agent, type) => {
  try {
    const sms = {
      id: generateUuid(),
      to: customerId,
      type,
      createdAt: firebase.firestore.Timestamp.now(),
    };
    const ref = getFirestore().collection('agents').doc(agent);

    // await ref.update({
    //   sms: firebase.firestore.FieldValue.increment(1),
    // });

    await ref.collection('sms').doc(sms.id).set(sms);
    return true;
  } catch (e) {
    console.error(`Fail updating agent ${agent} sms count`, e.stack);
    return false;
  }
};

export const getAllAgents =
  (forceUpdate = false) =>
  async (dispatch, getState) => {
    let agents = getState().agents?.agents;
    if (!agents || forceUpdate) {
      agents = await dispatch(fetchAndStoreAllAgents());
    }

    return agents;
  };

export const getAgentsByParams =
  ({ page = 0, pageSize = 5, query = null, forceUpdate = false }) =>
  async (dispatch) => {
    let agents = await dispatch(getAllAgents(forceUpdate));

    if (query?.role && query?.role !== ROLES.ALL) {
      agents = agents.filter(({ role }) => role === query.role);
    }

    if (query?.email) {
      agents = agents.filter(({ email }) => email === query.email);
    }

    return agents.slice(page * pageSize, page * pageSize + pageSize);
  };

const fetchAndStoreAllAgents = () => async (dispatch) => {
  const db = getFirestore();
  const querySnapshot = await db.collection('agents').orderBy('email').get();
  const agents = querySnapshot.docs.map((doc) => mapAgent(doc.data()));

  dispatch(actions.setAgents(agents));

  return agents;
};

const mapAgent = (data) => ({
  uuid: data.uuid,
  name: `${data.firstName} ${data.lastName}`.trim(),
  role: data.role,
  email: data.email,
  active: !data.disabled,
});
