import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { PAYMENT_PLAN_STATUS } from 'src/constants';
import { formatDate, monthDays, monthLapse, parseDate, previousMonths } from 'src/utils/dateUtils';
import getFirestore from 'src/utils/firestore';
import { getAmortizedByAgentInCurrentMonth } from './payments';
import { getPaymentPlansUnSync } from './plans';

const initialState = {
  overview: null,
  entitiesTotals: null,
};

const slice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    getOverview(state, action) {
      state.overview = action.payload;
    },

    getEntitiesTotals(state, action) {
      state.entitiesTotals = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getAdminOverview = () => async (dispatch) => {
  return getFirestore()
    .collection('overview')
    .doc('adminEntitiesOverview')
    .onSnapshot((querySnapshot) => {
      if (querySnapshot.exists) {
        dispatch(slice.actions.getOverview(querySnapshot.data()));
      }
    });
};

export const getEntitiesTotals = () => async (dispatch) => {
  return getFirestore()
    .collection('overview')
    .doc('entitiesTotals')
    .onSnapshot((querySnapshot) => {
      if (querySnapshot.exists) {
        dispatch(slice.actions.getEntitiesTotals(querySnapshot.data()));
      }
    });
};

export const getOverview = (uid) => async (dispatch) => {
  const sms = await calculateMonthSms(uid);
  const overview = await calculateOverview(uid);
  const plans = await calculatePaymentPlans(uid);

  const comunication = {
    calls: { value: 0, estimator: 'actualMonth' },
    minutes: { value: 0, estimator: 'today' },
    sms: { value: sms, estimator: 'actualMonth' },
    emails: { value: 0, estimator: 'actualMonth' },
  };

  const agetnOverview = {
    overview,
    plans,
    comunication,
    penalties: { value: 0, estimator: 'actualMonth', status: 'wrong' },
    chargebacks: { value: 0, estimator: 'general', status: 'wrong' },
  };

  dispatch(slice.actions.getOverview(agetnOverview));
};

const calculateMonthSms = async (uid) => {
  try {
    const { startDate, endDate } = monthLapse(new Date());
    const querySnapshot = await getFirestore()
      .collection('agents')
      .doc(uid)
      .collection('sms')
      .where('createdAt', '>=', startDate)
      .where('createdAt', '<=', endDate)
      .get();

    return querySnapshot.size;
  } catch (e) {
    console.error(`Fail getting sms communication from user ${uid}`, e);
    return 0;
  }
};

const calculateOverview = async (uid) => {
  const recovered = await getAmortizedByAgentInCurrentMonth(uid);
  const comissioned = recovered * 0.025;

  return {
    recovered: {
      value: recovered,
      estimator: 'actualMonth',
    },
    dailyRecovered: {
      value: recovered / monthDays(),
      estimator: 'generalAverage',
    },
    comission: {
      value: comissioned > 125000 ? 125000 : comissioned,
      estimator: 'actualMonth',
    },
  };
};

const calculatePaymentPlans = async (uid) => {
  const paymentPlans =
    (await getPaymentPlansUnSync({
      agent: uid,
    })) || [];

  let activePlans = {};
  let duePlans = {};
  let averagePlans = {};

  previousMonths(13).forEach((it) => {
    activePlans[it.short] = { month: it.monthName, value: 0 };
    duePlans[it.short] = { month: it.monthName, value: 0 };
    averagePlans[it.short] = { month: it.monthName, value: 0 };
  });

  paymentPlans?.forEach((plan) => {
    const d = formatDate(new Date(plan.createdAt || plan.updatedAt), 'MM/yyyy');
    if (plan.status === PAYMENT_PLAN_STATUS.ACTIVE) {
      activePlans[d].value = activePlans[d]?.value + 1 ?? 1;
    } else if (plan.status === PAYMENT_PLAN_STATUS.DUE) {
      duePlans[d].value = duePlans[d]?.value + 1 ?? 1;
    }
  });

  Object.keys(averagePlans).forEach((it) => {
    averagePlans[it].value = +(activePlans[it].value / monthDays(parseDate(it, 'MM/yyyy'))).toFixed(
      2
    );
  });

  const completedPlans = paymentPlans.filter((it) => it.status === PAYMENT_PLAN_STATUS.COMPLETED);
  const alivePlans = paymentPlans.filter(
    (it) => it.status === PAYMENT_PLAN_STATUS.ACTIVE || it.status === PAYMENT_PLAN_STATUS.DUE
  );

  const fee = isEmpty(alivePlans)
    ? 0
    : alivePlans.map((it) => it.instalments[0].amount).reduce((a, b) => a + b, 0) /
      alivePlans.length;

  return {
    compliance: {
      value: +((completedPlans.length / paymentPlans.length) * 100 || 0).toFixed(2),
      estimator: 'average',
      type: 'percentage',
    },
    averageAmount: {
      value: fee,
      estimator: 'general',
      type: 'currency',
    },
    active: activePlans,
    due: duePlans,
    average: averagePlans,
  };
};
