import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import { ROLES } from './constants';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Incidents = Loadable(lazy(() => import('./pages/dashboard/Incidents')));
const Performance = Loadable(lazy(() => import('./pages/dashboard/Performance')));
const Payments = Loadable(lazy(() => import('./pages/dashboard/Payments')));
const PaymentPlans = Loadable(lazy(() => import('./pages/dashboard/PaymentPlans')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const Monitorio = Loadable(lazy(() => import('./pages/dashboard/Monitorio')));
const FinanceTaxes = Loadable(lazy(() => import('./pages/dashboard/FinanceTaxes')));

// Admin pages

const Paleator = Loadable(lazy(() => import('./pages/paleator/PaleatorScreen')));
const ImportLoans = Loadable(lazy(() => import('./pages/admin/ImportLoans')));
const ImportPhones = Loadable(lazy(() => import('./pages/admin/ImportPhones')));
const ImportPayments = Loadable(lazy(() => import('./pages/admin/ImportPayments')));
const PaymentLinkGenerator = Loadable(lazy(() => import('./pages/admin/PaymentLinkGenerator')));
const Agents = Loadable(lazy(() => import('./pages/agents/Agents')));

// Loans pages

const Loans = Loadable(lazy(() => import('./pages/loans/Loans')));
const LoanDetails = Loadable(lazy(() => import('./pages/loans/Details')));

// Customer pages

const Customer = Loadable(lazy(() => import('./pages/customer/Customer')));

// Payment pages

const PaymentLink = Loadable(lazy(() => import('./pages/payments/PaymentLink')));
const PaymentResponse = Loadable(lazy(() => import('./pages/payments/PaymentResponse')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: '/pago',
    children: [
      {
        path: ':ref_id',
        element: <PaymentLink />,
      },
      {
        path: 'success',
        element: <PaymentResponse success />,
      },
      {
        path: 'error',
        element: <PaymentResponse />,
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/',
            element: <Overview />,
          },
          {
            path: 'account',
            element: <Account />,
          },
          {
            path: 'incidents',
            element: (
              <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
                <Incidents />
              </AuthGuard>
            ),
          },
          {
            path: 'performance',
            element: (
              <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
                <Performance />
              </AuthGuard>
            ),
          },
          {
            path: 'monitorio',
            element: (
              <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
                <Monitorio />
              </AuthGuard>
            ),
          },
          {
            path: 'taxes',
            element: (
              <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
                <FinanceTaxes />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '/loans',
    element: (
      <MainLayout>
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <Loans />,
      },
      {
        path: ':loanId',
        element: <LoanDetails />,
      },
    ],
  },
  {
    path: '/paymentPlans',
    element: (
      <MainLayout>
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <PaymentPlans />,
      },
    ],
  },
  {
    path: '/pending-payments',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN, ROLES.SUPERVISOR]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <Payments />,
      },
    ],
  },
  {
    path: '/customer',
    element: (
      <MainLayout>
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: ':customerId',
        element: <Customer />,
      },
    ],
  },
  {
    path: '/agents',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <Agents />,
      },
    ],
  },
  {
    path: '/paleator',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <Paleator />,
      },
    ],
  },
  {
    path: '/import-loans',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <ImportLoans />,
      },
    ],
  },
  {
    path: '/import-payments',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <ImportPayments />,
      },
    ],
  },
  {
    path: '/import-phones',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <ImportPhones />,
      },
    ],
  },
  {
    path: '/payment-link',
    element: (
      <MainLayout>
        <AuthGuard roles={[ROLES.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <PaymentLinkGenerator />,
      },
    ],
  },
];

export default routes;
