import { combineReducers } from '@reduxjs/toolkit';

import { reducer as OverviewReducer } from '../slices/overview';
import { reducer as PlansReducer } from '../slices/plans';
import { reducer as LoanReducer } from '../slices/loan';
import { reducer as CustomerReducer } from '../slices/customer';
import { reducer as AgentReducer } from '../slices/agent';
import { reducer as ImportReducer } from '../slices/import';
import { reducer as IncidentReducer } from '../slices/incidents';
import { reducer as PendingPaymentsReducer } from '../slices/pendingPayments';
import { reducer as PaymentLinksReducer } from '../slices/paymentsLinks';
import { reducer as PaymentsReducer } from '../slices/payments';
import { reducer as MonitoriosReducer } from '../slices/monitorio';
import { reducer as FinanceReducer } from '../slices/financeTaxes';
import { reducer as BankAccountsReducer } from '../slices/bankAccount';
import { reducer as MerchantsReducer } from '../slices/merchant';

const rootReducer = combineReducers({
  overview: OverviewReducer,
  plans: PlansReducer,
  loans: LoanReducer,
  customers: CustomerReducer,
  agents: AgentReducer,
  import: ImportReducer,
  incidents: IncidentReducer,
  pendingPayments: PendingPaymentsReducer,
  paymentLinks: PaymentLinksReducer,
  payments: PaymentsReducer,
  monitorio: MonitoriosReducer,
  taxes: FinanceReducer,
  bankAccounts: BankAccountsReducer,
  merchants: MerchantsReducer,
});

export default rootReducer;
