export const chunk = (arr, size) => {
  if (arr) {
    const firstChunk = arr.slice(0, size);
    if (!firstChunk.length) {
      return arr;
    }
    return [firstChunk].concat(chunk(arr.slice(size, arr.length), size));
  }
  return [];
};
