import { useState } from 'react';
import {
  alpha,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchIcon from '../../icons/Search';
import XIcon from '../../icons/X';
import Scrollbar from '../Scrollbar';
import { useTranslation } from 'react-i18next';
import { DNIorNIE, isEmail, isPhone, isRefId } from 'src/utils/validators';
import { searchLoans } from 'src/slices/loan';
import ContentSearchResult from './search/ContentSearchResult';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const ContentSearch = () => {
  const { t } = useTranslation('search');
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(null);
  const [searchByDni, setSearchByDni] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const search = async () => {
    setIsLoading(true);
    setShowResults(false);
    setSearchByDni(DNIorNIE(value));

    const filters = {
      field: DNIorNIE(value)
        ? 'dni'
        : isPhone(value)
        ? 'phone'
        : isRefId(value)
        ? 'refId'
        : isEmail(value)
        ? 'email'
        : 'name',
      value,
    };

    searchLoans(filters)
      .then(setResults)
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
        setShowResults(true);
      });
  };

  const handleClick = () => {
    search();
  };

  const handleKeyUp = (event) => {
    if (event.code === 'Enter') {
      search();
    }
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleOpen} size="large">
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: '100%' },
        }}
        variant="temporary"
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={handleClose} size="large">
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3 }}>
          <Container maxWidth="md">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setValue(event.target.value)}
                onKeyUp={handleKeyUp}
                placeholder="Search..."
                value={value}
              />
              <Button
                color="primary"
                onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                variant="contained"
              >
                {t('cta')}
              </Button>
            </Box>
            <Scrollbar options={{ suppressScrollX: true }}>
              <Box sx={{ mt: 3, maxHeight: 300 }}>
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {showResults && (
                      <>
                        {results?.length > 0 ? (
                          results.map((result) => (
                            <ContentSearchResult
                              key={result.refId}
                              {...result}
                              onClick={handleClose}
                              searchByDni={searchByDni}
                            />
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Avatar
                              sx={{
                                backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.08),
                                color: 'warning.main',
                                mr: 2,
                              }}
                            >
                              <SentimentDissatisfiedIcon />
                            </Avatar>
                            <Typography>{t('common:errors.default')}</Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Scrollbar>
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

export default ContentSearch;
