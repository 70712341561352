import PropTypes from 'prop-types';
import { Box, Chip, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LOAN_STATUS } from 'src/constants';
import CheckIcon from 'src/icons/Check';
import ExclamationIcon from 'src/icons/Exclamation';
import { useTranslation } from 'react-i18next';
import BlockIcon from '@mui/icons-material/Block';

const ContentSearchResult = ({ id, dni, fullName, status, onClick, searchByDni }) => {
  const { t } = useTranslation('loans');

  const path = searchByDni ? `/customer/${dni}` : `/loans/${id}`;
  const linkValue = searchByDni ? dni : id;
  const body = searchByDni ? `${id} | ${fullName}` : `${dni} | ${fullName}`;

  const renderStatus = () => {
    let options = {};
    switch (status) {
      case LOAN_STATUS.ACTIVE:
        options = {
          icon: <CheckIcon />,
          color: 'primary',
          label: t('status.active'),
        };
        break;
      case LOAN_STATUS.DELINQUENT:
        options = {
          icon: <ExclamationIcon />,
          color: 'error',
          label: t('status.delinquent'),
        };
        break;
      case LOAN_STATUS.DEFECTIVE:
        options = {
          icon: <BlockIcon />,
          color: 'gray',
          label: t('loans:status.defective'),
        };
        break;
      case LOAN_STATUS.CLOSED:
        options = {
          icon: <CheckIcon />,
          color: 'success',
          label: t('status.closed'),
        };
        break;
      case LOAN_STATUS.BULNES_SOLD2022:
        options = {
          icon: <CheckIcon />,
          color: 'success',
          label: t('status.bulnes_sold'),
        };
        break;
      default:
    }
    return <Chip size="medium" {...options} />;
  };
  return (
    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {renderStatus()}
      <Box sx={{ ml: 2 }}>
        <Link color="textPrimary" component={RouterLink} to={path} variant="h5" onClick={onClick}>
          {linkValue}
        </Link>
        <Typography color="textPrimary" variant="body2">
          {body}
        </Typography>
      </Box>
    </Box>
  );
};

ContentSearchResult.propTypes = {
  id: PropTypes.string,
  dni: PropTypes.string,
  fullName: PropTypes.string,
  status: PropTypes.string,
  onClick: PropTypes.func,
  searchByDni: PropTypes.bool,
};

export default ContentSearchResult;
