import { dateParser } from './parser';

export const incidentConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return dateParser(data);
  },
};
