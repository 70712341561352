import { createSlice } from '@reduxjs/toolkit';
import getFirestore from 'src/utils/firestore';

const initialState = {
  bankAccounts: null,
};

const slice = createSlice({
  name: 'bankAccounts',
  initialState,
  reducers: {
    setBankAccounts(state, action) {
      state.bankAccounts = action.payload;
    },
  },
});

export const { actions, reducer } = slice;

export const getBankAccounts = () => async (dispatch) => {
  try {
    const querySnapshot = await getFirestore().collection('bankAccounts').get();
    if (querySnapshot.empty) {
      dispatch(actions.setBankAccounts(null));
    } else {
      dispatch(actions.setBankAccounts(querySnapshot.docs.map((it) => it.data())));
    }
  } catch (e) {
    console.error('Fail to retrieve bankAccounts', e);
    dispatch(actions.setBankAccounts(null));
  }
};
