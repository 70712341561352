import { PAYMENT_PLAN_INSTALMENT_STATUS } from 'src/constants';

const buildPlan = (customer, id, amount) => ({
  id,
  fee: amount,
  ...customer,
});

const getPendingInstalment = (instalments) =>
  instalments.find((it) => it.status === PAYMENT_PLAN_INSTALMENT_STATUS.PENDING);

export const planConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    const dueInstalment = getPendingInstalment(data?.instalments);

    if (!dueInstalment) {
      return null;
    }

    return buildPlan(data?.customer, data.loanId, dueInstalment.amount);
  },
};

export const reducedConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    const dueInstalment = getPendingInstalment(data?.instalments);

    if (!dueInstalment) {
      return null;
    }

    const planAmount = data.instalments.map((it) => it.amount).reduce((a, b) => a + b, 0);

    return {
      id: data.loanId || data.customerId,
      status: data.status,
      amount: dueInstalment.amount,
      remaining: dueInstalment.remaining,
      instalments: data.instalments.length,
      rate: dueInstalment.amount / planAmount,
    };
  },
};
