import {
  add,
  format,
  isAfter,
  parse,
  sub,
  startOfMonth,
  endOfMonth,
  isToday,
  startOfYear,
  endOfYear,
  subMonths,
  startOfDay,
  endOfDay,
  getDaysInMonth,
  subDays,
} from 'date-fns';
import { isDate } from 'lodash';

export const dateFormat = 'dd/MM/yyyy';
export const dateFormatToSearch = 'yyyy-MM-dd';
export const dateTimeFormat = 'dd/MM/yyyy HH:mm';
export const dateTimeWithSecodsFormat = 'dd/MM/yyyy HH:mm:ss';

export const today = format(new Date(), dateFormat);

export const isValidDate = (date) => isDate(date);

export const isSameAsToday = (date) => isToday(date);

export const isAfterOrToday = (date) => date > startOfDay(new Date());

export const isSameDay = (dateL, dateR) => formatDate(dateL) === formatDate(dateR);

export const isSameYear = (date, year) => new Date(date).getFullYear() === year;

export const isAfterDate = (dateL, dateR) => isAfter(dateL, dateR);

export const formatDate = (date, df = dateFormat) => format(new Date(date), df);

export const formatDateTime = (date, df = dateTimeFormat) => format(new Date(date), df);

export const parseDate = (date, format = dateFormat) => parse(date, format, new Date());

export const addMonthsToDate = (date, months) => add(date, { months });

export const addDaysToDate = (date, days) => add(date, { days });

export const subsMonthsToDate = (date, months) => subMonths(date, months);

export const subsDaysToDate = (date, days) => subDays(date, days);

export const currentYear = () => format(now(), 'yyyy');

export const currentMonth = () => format(now(), 'MM');

export const currentDay = () => format(now(), 'dd');

export const dayStart = (date) => startOfDay(date);

export const dayEnd = (date) => endOfDay(date);

export const monthDays = (date = new Date()) => getDaysInMonth(date);

export const dayLapseFromDate = (date) => {
  const d = new Date(date);
  return {
    startDate: startOfDay(d),
    endDate: endOfDay(d),
  };
};

export const monthLapse = (date = new Date()) => {
  return {
    startDate: startOfMonth(date),
    endDate: endOfMonth(date),
  };
};

export const monthLapseCurrent = (date = new Date()) => {
  return {
    startDate: startOfMonth(date),
    endDate: endOfDay(date),
  };
};

export const yearLapse = (date = new Date()) => {
  return {
    startDate: startOfYear(date),
    endDate: endOfYear(date),
  };
};

export const now = () => new Date();

export const yearMonths = () => {
  const { startDate, endDate } = yearLapse();
  const lastMonth = endDate.getMonth();
  const months = [];

  for (let i = 0; i <= lastMonth; i++) {
    const month = add(startDate, { months: i });
    months.push({
      number: format(month, 'MM'),
      monthName: format(month, 'MMM'),
    });
  }
  return months;
};

export const previousMonths = (monthsRequired = 11) => {
  const months = [];
  for (let i = 0; i < monthsRequired - 1; i++) {
    const month = sub(new Date(), { months: i });
    months.push({
      short: format(month, 'MM/yyyy'),
      monthName: format(month, 'MMM'),
    });
  }
  return months;
};
