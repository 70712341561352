import { PAYMENT_STATUS } from 'src/constants';
import { dateParser } from './parser';

export const approvedPaymentConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return {
      status: data.status,
      type: data.type,
      amount: data?.amount || 0,
    };
  },
};

export const summaryPaymentConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    const pendingPayment = {
      id: data.paymentId,
      status: data.status,
      loanId: data.loanId,
      entity: data.entity,
      refId: data.refId,
      loanCreationYear: data.loanCreationYear || '',
      createdAt: data.createdAt,
      confirmedAt: data.confirmedAt,
      confirmedAtIndx: data.confirmedAtIndx,
      amount: data.amount,
      dni: data.dni || '',
      fullName: data.fullName || '',
      transferConcept: data?.searchString || '',
      paymentMethod: data?.paymentMethod || '',
      splittedPayments: data?.splittedPayments || '',
      type: data?.type || '',
    };

    return dateParser(pendingPayment);
  },
};

export const mainConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    const payment = { ...data };

    if (payment.status === PAYMENT_STATUS.DECLINED) {
      const cbk = payment.callbacks.find((it) => it.type === 'payment_declined') || {
        data: { response_summary: 'error' },
      };
      payment.error = cbk.data.response_summary;
    }

    delete payment.callbacks;

    return dateParser(payment);
  },
};
