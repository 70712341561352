import { createSlice } from '@reduxjs/toolkit';
import { planConverter, reducedConverter } from './plansConverter';
import firebase from '../lib/firebase';
import getFirestore from 'src/utils/firestore';
import { addDaysToDate, dateFormatToSearch, formatDate, subsDaysToDate } from 'src/utils/dateUtils';
import { PAYMENT_PLAN_STATUS } from 'src/constants';
import { dateParser } from './parser';
import { chunk } from 'src/utils/array';
import { DNIorNIE, isEmail, isRefId } from 'src/utils/validators';
import { sanitizeText } from 'src/utils/stringUtils';

const initialState = {
  dueTomorrowPaymentPlans: null,
  cancelTomorrowPaymentPlans: null,
  paymentPlans: null,
};

const slice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    getDueTomorrowPaymentPlans(state, action) {
      state.dueTomorrowPaymentPlans = action.payload;
    },
    getCancelTomorrowPaymenPlans(state, action) {
      state.cancelTomorrowPaymentPlans = action.payload;
    },
    setPaymentPlans(state, action) {
      state.paymentPlans = action.payload;
    },
  },
});

export const { reducer } = slice;

const fetchPlans = (uid, options) => {
  let baseQuery = getFirestore()
    .collection('paymentPlans')
    .where('agent', '==', uid)
    .withConverter(planConverter);

  if (options.status) {
    baseQuery = baseQuery.where('status', '==', options.status);
  }

  if (options.due) {
    baseQuery = baseQuery.where('nextInstalmentDate', '==', options.due);
  }

  if (options.cancel) {
    baseQuery = baseQuery.where('nextInstalmentDate', '<', options.cancel);
  }

  return baseQuery;
};

export const getDueTomorrowPaymentPlans =
  (uid, page = 0, pageSize = 5) =>
  async (dispatch) => {
    const options = {
      due: formatDate(addDaysToDate(new Date(), 1), dateFormatToSearch),
      status: PAYMENT_PLAN_STATUS.ACTIVE,
    };
    return fetchPlans(uid, options).onSnapshot((querySnapshot) => {
      const arr = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((it) => it !== null && it !== undefined);

      dispatch(
        slice.actions.getDueTomorrowPaymentPlans({
          pages: arr.length,
          data: arr.slice(page * pageSize, page * pageSize + pageSize),
        })
      );
    });
  };

export const getCancelTomorrowPaymenPlans =
  (uid, page = 0, pageSize = 5) =>
  async (dispatch) => {
    const options = {
      due: formatDate(subsDaysToDate(new Date(), 5), dateFormatToSearch),
      status: PAYMENT_PLAN_STATUS.DUE,
    };
    return fetchPlans(uid, options).onSnapshot((querySnapshot) => {
      const arr = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((it) => it !== null && it !== undefined);

      dispatch(
        slice.actions.getCancelTomorrowPaymenPlans({
          pages: arr.length,
          data: arr.slice(page * pageSize, page * pageSize + pageSize),
        })
      );
    });
  };

const queryToGetPayments = (options) => {
  let baseQuery = getFirestore().collection('paymentPlans');

  if (options.createdAtStart || options.createdAtEnd) {
    baseQuery = baseQuery.orderBy('createdAt');
  } else {
    baseQuery = baseQuery.orderBy('dueDate');
  }
  if (options.sorted) {
    baseQuery = baseQuery.orderBy('status');
  }

  if (options.reference) {
    if (DNIorNIE(options.reference)) {
      baseQuery = baseQuery.where('customerId', '==', options.reference.toUpperCase());
    } else if (isEmail(options.reference)) {
      baseQuery = baseQuery.where('customerId', '==', options.reference);
    } else if (isRefId(options.reference)) {
      baseQuery = baseQuery.where('loanId', '==', options.reference);
    } else {
      baseQuery = baseQuery.where('customerId', '==', sanitizeText(options.reference));
    }
  }

  if (options.agent && options.agent !== 'ALL') {
    baseQuery = baseQuery.where('agent', '==', options.agent);
  }

  if (options.startDate) {
    const date = formatDate(options.startDate, dateFormatToSearch);
    baseQuery = baseQuery.where('nextInstalmentDate', '==', date);
  }

  if (options.createdAtStart) {
    baseQuery = baseQuery.where('createdAt', '>=', options.createdAtStart);
  }

  if (options.createdAtEnd) {
    baseQuery = baseQuery.where('createdAt', '<=', options.createdAtEnd);
  }

  if (options.status && options.status !== PAYMENT_PLAN_STATUS.ALL) {
    baseQuery = baseQuery.where('status', '==', options.status);
  }

  if (options.statuses) {
    baseQuery = baseQuery.where('status', 'in', options.statuses);
  }

  if (options.loanId) {
    baseQuery = baseQuery.where('loanId', '==', options.loanId);
  }

  if (options.loansIds) {
    baseQuery = baseQuery.where('loanId', 'in', options.loansIds);
  }

  if (options.customerId) {
    baseQuery = baseQuery.where('customerId', '==', options.customerId);
  }

  if (options.converter) {
    baseQuery = baseQuery.withConverter(reducedConverter);
  }

  if (options?.endBefore) {
    baseQuery = baseQuery.endBefore(options.endBefore).limitToLast(options.limit);
  }

  if (options?.startAfter) {
    const startAfter = options?.agentName
      ? [options.agentName, options.startAfter]
      : options.startAfter;
    baseQuery = baseQuery.startAfter(startAfter).limit(options.limit);
  }

  if (options.limit && !options.startAfter && !options.endBefore) {
    baseQuery = baseQuery.limit(options.limit);
  }

  return baseQuery;
};

const processQuery = (querySnapshot) => {
  if (!querySnapshot.empty) {
    return querySnapshot.docs.map((it) => dateParser(it.data()));
  }
  return null;
};

export const getPaymentPlans = (options) => async (dispatch, state) => {
  return queryToGetPayments({ sorted: true, ...options }).onSnapshot((querySnapshot) => {
    dispatch(slice.actions.setPaymentPlans(processQuery(querySnapshot)));
  });
};

export const getPaymentPlansUnSync = (options) => {
  return queryToGetPayments({ sorted: true, ...options })
    .get()
    .then((querySnapshot) => {
      return processQuery(querySnapshot);
    });
};

export const createPaymentPlan = async (loanId, paymentPlan, nextInstalmentDate) => {
  try {
    await getFirestore()
      .collection('paymentPlans')
      .doc(paymentPlan.id)
      .set({ ...paymentPlan, nextInstalmentDate });

    if (loanId) {
      return await updateLoanAfterCreatePaymentPlan(loanId, {
        hasPaymentPlan: true,
        agents: firebase.firestore.FieldValue.arrayUnion(paymentPlan.agent),
      });
    } else {
      return await updateLoanAfterCreatePaymentPlanForUser(paymentPlan.customer.dni, {
        agents: firebase.firestore.FieldValue.arrayUnion(paymentPlan.agent),
      });
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateLoanAfterCreatePaymentPlanForUser = (dni, values) => {
  return getFirestore()
    .collection('records')
    .where('customer.dni', '==', dni)
    .get()
    .then(async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const ids = querySnapshot.docs.map((it) => it.ref);
        const index = querySnapshot.size;

        let batch = firebase.firestore().batch();
        for (let i = 0; i < index; i++) {
          batch.update(ids[i], values);

          if ((i + 1) % 499 === 0) {
            await batch.commit();
            batch = firebase.firestore().batch();
          }
        }
        if (!(index % 499 === 0)) {
          await batch.commit();
        }
      }
    })
    .catch((e) => console.error(e));
};

const updateLoanAfterCreatePaymentPlan = (loanId, body) => {
  return getFirestore()
    .collection('records')
    .doc(loanId)
    .update(body)
    .catch((e) => console.error(e));
};

export const cancelPaymentPlan = (id, agent) => {
  const ref = getFirestore().collection('paymentPlans').doc(id);
  return getFirestore().runTransaction((transaction) => {
    return transaction.get(ref).then(async (querySnapshot) => {
      if (querySnapshot.exists) {
        const paymentPlan = querySnapshot.data();
        if (paymentPlan.loanId) {
          await updateLoanAfterCreatePaymentPlan(paymentPlan.loanId, {
            hasPaymentPlan: false,
            agents: firebase.firestore.FieldValue.arrayRemove(agent),
          });
        } else if (paymentPlan.customerId) {
          await updateLoanAfterCreatePaymentPlanForUser(paymentPlan.customer.dni, {
            agents: firebase.firestore.FieldValue.arrayRemove(paymentPlan.agent),
          });
        }
        transaction.update(ref, {
          status: PAYMENT_PLAN_STATUS.CANCELLED,
          updatedAt: firebase.firestore.Timestamp.now(),
        });
      }
    });
  });
};

export const customerHasPaymentPlan = (customerId, converter = false) => {
  return queryToGetPayments({
    customerId,
    statuses: [PAYMENT_PLAN_STATUS.ACTIVE, PAYMENT_PLAN_STATUS.DUE],
    converter,
  })
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        return false;
      }
      return querySnapshot.docs.map((it) => it.data())[0];
    })
    .catch(() => false);
};

export const customerLoansHasPaymentPlan = (loansIds, converter = false) => {
  const chunkedids = chunk(loansIds, 9);
  const validStatus = [PAYMENT_PLAN_STATUS.ACTIVE, PAYMENT_PLAN_STATUS.DUE];

  return Promise.all(
    chunkedids.map((ids) => {
      return queryToGetPayments({
        loansIds: ids,
        converter,
      })
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return [];
          }
          return querySnapshot.docs
            .map((it) => it.data())
            .filter((it) => {
              return validStatus.includes(it.status);
            });
        })
        .catch((e) => {
          console.error(e);
          return [];
        });
    })
  )
    .then((res) => {
      return res.flatMap((it) => it);
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};
