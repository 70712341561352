import { createSlice } from '@reduxjs/toolkit';
import {
  customerLoanConverter,
  idConverter,
  loanConverter,
  searchLoanConverter,
} from './loanConverter';
import getFirestore from 'src/utils/firestore';
import { getAllAgents } from './agent';
import { isArray, isEmpty } from 'lodash';
import { sanitizeText } from 'src/utils/stringUtils';
import { LOAN_STATUS, PAYMENT_FILTER } from 'src/constants';

const initialState = {
  loan: null,
  error: null,
  loans: [],
};

const slice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    setLoans(state, action) {
      state.loans = action?.payload || [];
    },
    getLoan(state, action) {
      if (action.payload) {
        state.loan = action.payload;
        state.error = false;
      } else {
        state.loan = null;
        state.error = true;
      }
    },
  },
});

export const { reducer } = slice;

export const getLoanDetails = (loanId) => async (dispatch) => {
  return getFirestore()
    .collection('records')
    .withConverter(loanConverter)
    .doc(loanId)
    .onSnapshot((doc) => {
      const loan = doc.data();
      if (loan && !isEmpty(loan.notes)) {
        dispatch(getAllAgents());
      }

      dispatch(slice.actions.getLoan(loan));
    });
};

export const getLoan = async (loanId) => {
  try {
    const querySnapshot = await getFirestore()
      .collection('records')
      .withConverter(loanConverter)
      .doc(loanId)
      .get();
    return querySnapshot.exists ? querySnapshot.data() : null;
  } catch (e) {
    return null;
  }
};

const queryBuilderToFetchLoans = (options) => {
  let baseQuery = getFirestore()
    .collection('records')
    .withConverter(idConverter)
    .orderBy('__name__');

  if (options.agent) {
    baseQuery = baseQuery.where('agents', 'array-contains', options.agent);
  }

  if (options.refId) {
    baseQuery = baseQuery.where('refId', '==', options.refId);
  }

  if (options.dni) {
    baseQuery = baseQuery.where('customer.dni', '==', options.dni.toUpperCase());
  }

  if (options.country && options.country !== 'all') {
    baseQuery = baseQuery.where('customer.country', '==', options.country.toUpperCase());
  }

  if (options.city) {
    baseQuery = baseQuery.where('customer.city_lowercase', '==', sanitizeText(options.city));
  }

  if (options.email) {
    baseQuery = baseQuery.where('customer.email', '==', sanitizeText(options.email));
  }

  if (options.phone) {
    baseQuery = baseQuery.where(
      'customer.phoneNumbers',
      'array-contains',
      sanitizeText(options.phone)
    );
  }

  if (options.status && options.status !== LOAN_STATUS.ALL) {
    const comparision = isArray(options.status) ? 'in' : '==';
    baseQuery = baseQuery.where('status', comparision, options.status);
  }

  if (options.entity && !isEmpty(options.entity) && !options.entity.includes(PAYMENT_FILTER.ALL)) {
    baseQuery = baseQuery.where('entityName', 'in', options.entity);
  }

  if (options.creationYear && options.creationYear !== PAYMENT_FILTER.ALL) {
    baseQuery = baseQuery.where('creationYear', '==', options.creationYear);
  }

  if (options.merchants && options.merchants !== PAYMENT_FILTER.ALL) {
    baseQuery = baseQuery.where('merchant_lowercase', '==', options.merchants);
  }

  if (options.hasPayments) {
    if (options.hasPayments === PAYMENT_FILTER.HAVE) {
      baseQuery = baseQuery.where('hasPayments', '==', true);
    } else if (options.hasPayments === PAYMENT_FILTER.HAVE_NOT) {
      baseQuery = baseQuery.where('hasPayments', '==', false);
    }
  }

  if (options.hasPaymentPlan) {
    if (options.hasPaymentPlan === PAYMENT_FILTER.HAVE) {
      baseQuery = baseQuery.where('hasPaymentPlan', '==', true);
    } else if (options.hasPaymentPlan === PAYMENT_FILTER.HAVE_NOT) {
      baseQuery = baseQuery.where('hasPaymentPlan', '==', false);
    }
  }

  if (options.hasMonitorio) {
    if (options.hasMonitorio === PAYMENT_FILTER.HAVE) {
      baseQuery = baseQuery.where('hasMonitorio', '==', true);
    } else if (options.hasMonitorio === PAYMENT_FILTER.HAVE_NOT) {
      baseQuery = baseQuery.where('hasMonitorio', '==', false);
    }
  }

  if (options?.endBefore) {
    baseQuery = baseQuery.endBefore(options.endBefore).limitToLast(options.limit);
  }

  if (options?.startAfter) {
    baseQuery = baseQuery.startAfter(options.startAfter).limit(options.limit);
  }

  if (options.limit) {
    baseQuery = baseQuery.limit(options.limit);
  }
  return baseQuery;
};

export const fetchLoans = (options) => async (dispatch) => {
  return queryBuilderToFetchLoans(options)
    .withConverter(idConverter)
    .onSnapshot((querySnapshot) => {
      const fetchedLoans = querySnapshot.docs.map((it) => it.data());
      dispatch(slice.actions.setLoans(fetchedLoans));
    });
};

export const getLoans = (options) => async () => {
  return queryBuilderToFetchLoans(options)
    .withConverter(idConverter)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        return null;
      }
      return querySnapshot.docs.map((it) => it.data());
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const searchLoans = async (filters) => {
  let baseQuery = getFirestore().collection('records').withConverter(searchLoanConverter);

  if (filters?.field === 'dni') {
    baseQuery = baseQuery.where('customer.dni', '==', filters.value.toUpperCase());
  }

  if (filters?.field === 'name') {
    const filter = sanitizeText(filters.value);

    baseQuery = baseQuery
      .orderBy('customer.fullName_lowercase')
      .startAt(filter)
      .endAt(filter + '\uf8ff');
  }

  if (filters?.field === 'refId') {
    const refId = filters.value.match(/(A\d+)|(FV\d+)|(\d+)/gi)[0];
    baseQuery = baseQuery.where('refId', '==', refId);
  }

  if (filters?.field === 'email') {
    baseQuery = baseQuery.where('customer.email', '==', sanitizeText(filters.value));
  }

  if (filters?.field === 'phone') {
    baseQuery = baseQuery.where('customer.phone', '==', sanitizeText(filters.value));
    // baseQuery = baseQuery.where(
    //   'customer.phoneNumbers',
    //   'array-contains',
    //   sanitizeText(filters.value)
    // );
  }

  if (filters.status) {
    baseQuery = baseQuery.where('status', 'in', filters.status);
  }

  const querySnapshot = await baseQuery.get();

  if (querySnapshot.empty) {
    return null;
  }
  return querySnapshot.docs.map((it) => it.data());
};

export const update = (loanId, values) => {
  return getFirestore()
    .collection('records')
    .doc(loanId)
    .update({
      ...values,
    });
};

export const checkUserForLoans = async (loanIds) => {
  try {
    const querySnapshot = await getFirestore()
      .collection('records')
      .where('__name__', 'in', loanIds)
      .withConverter(customerLoanConverter)
      .get();
    if (querySnapshot.empty || querySnapshot.size !== loanIds.length) {
      return false;
    }

    const users = querySnapshot.docs.map((it) => it.data());
    const userSet = new Set(users);
    return userSet.size === 1;
  } catch (e) {
    return false;
  }
};
