import { LOAN_STATUS } from 'src/constants';

export const customerConverter = {
  toFirestore(req) {
    return req;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return {
      ref: snapshot.ref,
      customer: data.customer,
      deleted: data.customerDeleted,
      totalPaid: data?.amortized || 0,
      lend: data.status !== LOAN_STATUS.DEFECTIVE ? data.total : 0,
      minToClose:
        data.status !== LOAN_STATUS.DEFECTIVE ? Math.ceil(data?.total * data?.discount) : 0,
      loan: { id: snapshot.id, ...data },
    };
  },
};
