import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';

import { firebaseConfig } from '../config';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.app().functions('europe-west2');
const remoteConfig = firebase.remoteConfig();

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig
  .fetchAndActivate()
  .then(() => console.log('remoteConfig fetched'))
  .catch((e) => console.error('error fetching config', e));

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && !window['_init']) {
  firestore.useEmulator('localhost', 8080);
  firestore.settings({
    experimentalForceLongPolling: true,
    merge: true,
  });
  auth.useEmulator('http://localhost:9099');
  storage.useEmulator('localhost', 9199);
  functions.useEmulator('localhost', 5001);

  if (typeof window !== 'undefined') {
    window['_init'] = true;
  }
}

export default firebase;
