import { createSlice } from '@reduxjs/toolkit';
import getFirestore from 'src/utils/firestore';
import firebase from 'src/lib/firebase';
import { dateParser } from './parser';

const initialState = {
  loan: null,
  error: null,
};

const slice = createSlice({
  name: 'paymentLinks',
  initialState,
  reducers: {
    setLoan(state, action) {
      state.loan = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getPaymentLink = (id) => async (dispatch) => {
  dispatch(slice.actions.setError(false));
  getFirestore()
    .collection('paymentLinks')
    .doc(id)
    .get()
    .then((querySnapshot) => {
      const data = querySnapshot.data();
      if (data) {
        dispatch(slice.actions.setLoan(dateParser(data)));
      } else {
        dispatch(slice.actions.setError(true));
      }
    })
    .catch((e) => {
      console.error(e);
      dispatch(slice.actions.setError(true));
    });
};

export const storePaymentLink = async (loan, customerId, amount, agentId) => {
  const storePaymentFunction = firebase
    .app()
    .functions('europe-west2')
    .httpsCallable('storePaymentLink');

  try {
    const paymentLinkId = await storePaymentFunction({ amount, loan, customerId, agentId });
    return paymentLinkId?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
