import { createSlice } from '@reduxjs/toolkit';
import { MONITORIO_STATUS } from 'src/constants';
import getFirestore from 'src/utils/firestore';
import firebase from 'src/lib/firebase';
import { monitorioConverter, reducedMonitorioConverter } from './monitorioConverter';
import { sanitizeText } from 'src/utils/stringUtils';

const initialState = {
  monitorios: null,
};

const slice = createSlice({
  name: 'monitorio',
  initialState,
  reducers: {
    setMonitorios(state, action) {
      state.monitorios = action.payload;
    },
  },
});

export const { reducer } = slice;

const queryToMonitorios = (options) => {
  let baseQuery = getFirestore().collection('monitorios').orderBy('createdAt', 'desc');

  if (options.loanId) {
    baseQuery = baseQuery.where('loanId', '==', options.loanId);
  }

  if (options.decan) {
    baseQuery = baseQuery.where('city_lowercase', '==', options.decan.toLowerCase());
  }

  if (options.status && options.status !== MONITORIO_STATUS.ALL) {
    baseQuery = baseQuery.where('status', '==', options.status);
  }

  if (options?.endBefore) {
    baseQuery = baseQuery.endBefore(options.endBefore).limitToLast(options.limit);
  }

  if (options?.startAfter) {
    baseQuery = baseQuery.startAfter(options.startAfter).limit(options.limit);
  }

  if (options.limit) {
    baseQuery = baseQuery.limit(options.limit);
  }
  return baseQuery;
};

export const fetchMonitorios = (options) => async (dispatch) => {
  return queryToMonitorios(options)
    .withConverter(monitorioConverter)
    .onSnapshot((querySnapshot) => {
      if (querySnapshot.empty) {
        dispatch(slice.actions.setMonitorios(null));
      }
      const monitorios = querySnapshot.docs.map((it) => it.data());
      dispatch(slice.actions.setMonitorios(monitorios));
    });
};

export const getMonitorios = (options) => {
  return queryToMonitorios(options)
    .withConverter(reducedMonitorioConverter)
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        return querySnapshot.docs.map((it) => it.data());
      }
      return [];
    });
};

export const validatePersonalData = (id, status, values) => {
  return getFirestore()
    .collection('monitorios')
    .doc(id)
    .update({
      ...values,
      city_lowercase: sanitizeText(values.city),
      status,
      updatedAt: firebase.firestore.Timestamp.now(),
    });
};

export const update = (loanId, values) => {
  return getFirestore()
    .collection('monitorios')
    .where('loanId', '==', loanId)
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.docs[0].ref.update({ ...values });
      }
    });
};
